body {
  margin: 0;
}

#root {
  height: 100vh;
}

.doc-explorer-title-bar {
  padding: 0 !important;
}

.doc-explorer-title {
  font-weight: normal !important;
}

.ant-layout-header {
  background: #f1f1f1 !important;
  padding: 0 20px !important;
}

.ant-input-group-addon {
  color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 0.4;
  cursor: not-allowed;
}

th {
  font-weight: 600 !important;
}

.docExplorerWraps .docExplorerHide {
  display: none !important;
}
